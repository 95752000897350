import React from 'react';
import './header.css';
import people from '../../assets/people.png'
import ai from '../../assets/ai.png'

const Header = () => {
    return (
        <div className='gpt3__header section__padding' id="home">
            <div className='gpt3__header-content'>
                <h1 className='gradient__text'>Lets Build Something amazing with GPT-3 OpenIA</h1>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad expedita distinctio at odio illo eaque quisquam minus error nesciunt reiciendis et rerum, sapiente, eligendi facere laudantium eius tenetur porro deleniti!</p>
                <div className='gpt3__header-content__input'>
                    <input typ="email" placeholder="Your email adress"></input>
                    <button typ="button">Get Started</button>
                </div>
                <div className='gpt3__header-content__people'>
                    <img src={people} alt="people"/>
                    <p>1.600 people requested access a visit in last 24 hours</p>
                </div>

            </div>
                <div className='gpt3__header-image'>
                    <img src={ai} alt="ia"/>
                </div>
        </div>
    )
}

export default Header
